import React, { useState, useEffect } from "react";
import "./newsandevents.css";
import NavBar from "../NavBar";
import Footer from "../subcomponents/footer";
import Slider from "react-slick";
import imageofOrg from "../../assests/images/orgnaziation.png";
import sifcImage from "../../assests/images/Asset 1@2x.png";
import successsImage1 from "../../assests/images/96cd2e8d-7634-49d3-8421-cb6f667416fd.jpg";
import successsImage2 from "../../assests/images/leadership_images/1.jpeg";
import msg from "../../assests/images/msg.png";
import adnan from "../../assests/images/adnan.jpeg";
import billal from "../../assests/images/billal.jpeg";
// import jahanzaib from "../../assests/images/jahanzaib.jpeg";
import amirZaib from "../../assests/images/AmirZaib.jpg";
import moiz from "../../assests/images/moiz.jpg";
import tahir from "../../assests/images/tahir.jpeg";
// import zaheer from "../../assests/images/zaheer.jpeg";
import qaiser from "../../assests/images/QaiserKhan.jpg";
import Jamil from "../../assests/images/jamil.png";
import aatif from "../../assests/images/aatif.png";
import zafar from "../../assests/images/zafar.jpg";
import mahnoor from "../../assests/images/mahnoor.png";
import azam from "../../assests/images/azam.jpeg";
import naila from "../../assests/images/naila.png";
// import zafar from "../../assests/images/zafar.png";
import nighat from "../../assests/images/ms nighat.jpg";
import marukh from "../../assests/images/marukh .jpg";
import asad from "../../assests/images/Asad-Hussain.jpg";
import humaira from "../../assests/images/Ms-Humaira-.jpg";
import umar from "../../assests/images/Mr-Umar.jpg";
const Leadership_org = () => {
  const [isActiveOne, setIsActiveOne] = useState(true);
  const [isActiveTwo, setIsActiveTwo] = useState(true);
  const [isActiveThree, setIsActiveThree] = useState(true);
  const [isHovered, setHovered] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    color: scrolling ? "#000" : "#fff",
  };
  return (
    <div>
      <div className="bgPictureLeadershipOrg">
        <div
          className="relative bg-cover bg-center h-[554px] overflow-hidden bg-white bgPictureLeadershipOrg"
          style={{
            transition: "background-image 1s ease", // Smooth transition
          }}
        >
          {/* Navbar */}
          <div className="relative">
            <nav className="w-full fixed top-0 z-50 " style={navbarStyle}>
              <NavBar />
            </nav>
          </div>
          <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
            <div className="container mx-auto mt-[60px]">
              <div className="contianer-width flex flex-col items-center p-10">
                <h1 className="missionBoxHeading !text-white !text-6xl ">
                  Leadership
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* carousol  Events */}
      {/* Prime Minister */}
      <div>
        <div class="  relative  ">
          <div className=" absolute md:block hidden top-0 left-0 -z-10 w-2/6 h-full bg-[#073332]"></div>
          <div className="flex flex-row justify-center items-center py-8 md:py-[100px]">
            <div className="contianer-width mx-4">
              <div
                id="PM"
                className="flex flex-col md:grid md:grid-cols-12 gap-8"
              >
                <div className="col-span-5 justify-center flex">
                  <img
                    src={successsImage1}
                    alt="1"
                    className="h-full object-cover flex justify-center "
                  />
                </div>
                <div className="col-span-7">
                  <h3 className="text-[36px] mb-2 text-[#073332] font-bold text-center md:text-left">
                    Mian Muhammad Shehbaz Sharif
                  </h3>
                  <div className="text-[#073332] text-center md:text-left text-[20px] font-normal">
                    Prime Minister
                  </div>
                  <div className="text-[#073332] mb-5 text-center md:text-left text-[20px] font-normal">
                    Islamic Republic of Pakistan
                  </div>
                  <p className="textoftheHirreachy text-justify ">
                    {/* With over 240 million inhabitants, Pakistan offers its
                    investors a strong and large consumer market with an ever
                    expanding middle class. Strategic location at the crossroads
                    of South Asia, Central Asia and West Asia and close
                    proximity to the Gulf countries, makes Pakistan a promising
                    regional hub and an important market for intra and
                    inter-regional trade and investment. Pakistan as an
                    investment destination, offers investor friendly policies
                    with the commitment to resolve and enhance the business
                    competitiveness in the region. Almost all sectors of economy
                    are open to investment with attractive incentives and
                    liberal policies to give investors a competitive and
                    thriving business environment. Pakistan has an edge over all
                    its regional competitors. The country offers equal
                    opportunities to foreign and local investors. As an emerging
                    economy, Pakistan offers the opportunity of diversification
                    to global players with strong intention to structural
                    reforms in various sectors of the economy. Pakistan presents
                    vast investment opportunities with lucrative returns to
                    investors. The Government, as a policy leader, has
                    significant role of facilitating entrepreneurs to enhance
                    ease of doing business. We have mandated the SIFC to serve
                    as a facilitator to investors. The SIFC combine all
                    stakeholders at a single platform. Federal Ministries,
                    Provincial and local governments are integrated in the forum
                    with backup from Pakistan Army. It is truly a single window
                    and I am sure that there will be facilitation to the
                    investors at the highest level of the Government as well. I
                    strongly believe that this is the right time to invest in
                    Pakistan - don't miss the opportunity. */}
                    Within the intricate fabric of global investment landscapes,
                    the Special Investment Facilitation Council stands as a
                    testament to Pakistan's unwavering commitment to fostering a
                    robust and conducive business environment. In a nation of
                    over 240 million individuals, we present a dynamic market,
                    offering unparalleled opportunities for growth and
                    prosperity.
                    <br />
                    Strategically positioned at the crossroads of South Asia,
                    Central Asia, and West Asia, with proximity to Gulf
                    countries, Pakistan emerges as a linchpin, facilitating
                    dynamic international trade. This strategic location
                    positions our nation as a pivotal hub, connecting diverse
                    markets and fostering a climate ripe for cross-regional
                    collaboration and investment.
                    <br />
                    At the heart of our commitment to attract global investment
                    is the SIFC. This institution serves as the cornerstone,
                    providing meticulous support and facilitation at every stage
                    of your investment journey. From the energy and agriculture
                    sectors to IT and minerals, virtually every sector of our
                    economy extends an invitation for investment, promising
                    avenues of diversified growth.
                    <br />
                    This commitment to growth extends to both local and
                    international investors, underpinned by our dedicated
                    pursuit of structural reforms. These reforms, designed to
                    nurture a conducive business ecosystem, underscore our
                    resolve to provide equal opportunities for success,
                    regardless of the investor's origin.
                    <br />
                    As mandated by the government, the SIFC operates as a
                    unified platform, seamlessly integrating all stakeholders.
                    This integration ensures streamlined processes and affirms
                    our commitment to providing the highest echelon of
                    government support. The current juncture is ripe with
                    opportunity. It beckons investors to embrace the expansive
                    prospects that Pakistan offers. I extend a personal
                    invitation to you to join hands with us in sculpting the
                    future landscape of business and investment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class=" relative">
          <div className=" absolute md:block hidden top-0 right-0 -z-10 w-2/6 h-full bg-[#073332]"></div>
          <div className="flex flex-row justify-center items-center py-8 md:py-[100px]">
            <div className="contianer-width mx-4">
              <div
                id="COAS"
                className="flex flex-col-reverse md:grid md:grid-cols-12 gap-8"
              >
                <div className="col-span-7 flex  justify-center items-start flex-col">
                  <h3 className="text-[36px] mb-2 text-[#073332] font-bold text-center md:text-left">
                    General Syed Asim Munir, NI (M)
                  </h3>
                  <div className="text-[#073332] text-center md:text-left text-[20px] font-normal">
                    Chief of the Army Staff
                  </div>
                  <div className="text-[#073332] mb-5 text-center md:text-left text-[20px] font-normal">
                    Islamic Republic of Pakistan
                  </div>
                  <p className="textoftheHirreachy text-justify">
                    Our country is generously bestowed by Allah Almighty with
                    abundant resources and a nation with zeal, talent and
                    resilience. Together, we have always stood up to myriad
                    challenges with unwavering resolve and today’s economic woes
                    are also scalable, provided we harness our latent potential.
                    In a quest to rekindle the economy, Government of Pakistan
                    launched Economic Revival Plan for attracting investments.
                    At the heart of this endeavour stands Special Investment
                    Facilitation Council (SIFC), which envisions to transform
                    our economic approach – debt-driven to investment-driven. As
                    a 'Single Window' platform, it stands to optimize
                    horizontal-vertical synergy, harmonise diverse stakeholders,
                    ensure ease of doing business and help remove barriers.
                    Under its banner, we welcome investments from brotherly and
                    friendly countries in high-dividend sectors of Agriculture,
                    Mining and Minerals, Information Technology and Energy. We
                    are optimistic that SIFC will create win-win paradigm for
                    Pakistan as well as our brotherly and friendly countries by
                    capitalising on the comparative advantages. Pakistan Army
                    has been at the fore front for serving the people of
                    Pakistan and today we do not shy away from helping revive
                    country’s economy. We believe that by adopting collaborative
                    whole of the government approach, we can reap envisioned
                    macro-economic as well as socio-economic dividends.
                  </p>
                </div>
                <div className="col-span-5 justify-center flex">
                  <img
                    src={successsImage2}
                    alt="1"
                    className="h-full object-cover flex justify-center "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="bgPictureLeadershipOrgLeadership">
          <div
            className="relative bg-cover bg-center h-[554px] overflow-hidden bg-white bgPictureLeadershipOrgLeadership"
            style={{
              transition: "background-image 1s ease", // Smooth transition
            }}
          >
            {/* Navbar */}
            <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
              <div className="container mx-auto mt-[60px]">
                <div className="contianer-width flex flex-col items-center p-10">
                  <h1 className="missionBoxHeading !text-white !text-6xl">
                    Organization
                  </h1>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="organization"
          className="flex flex-row justify-center item-center "
        >
          <div className="contianer-width">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 py-[50px] items-center px-4">
              <div>
                <div className="headingofAaas mt-8 !text-4xl text-center lg:text-left pb-[5px]">
                  Inclusive Decision Making & Leadership Oversight{" "}
                </div>
                <div className="textofAboutUs text-left mt-[14px] !font-bold">
                  Fostering horizontal & vertical collaboration for investment
                  facilitation
                  <div className="textoftheHirreachy text-justify mt-[22px]">
                    SIFC operates as an inclusive organization, representing
                    federal and provincial stakeholders across all tiers, with
                    facilitation support from the military. The structure
                    includes three key committees: the Apex Committee, the
                    Executive Committee, Implementation Committee, and Sectoral
                    Working Groups.
                  </div>
                </div>
              </div>

              <div
                className="relative"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <div className="absolute22 center z-1">
                  <img src={sifcImage} alt="Centered Image" />
                </div>
                <div className="rotating-container z-0">
                  <img
                    src={imageofOrg}
                    className={`rotating-image ${isHovered ? "paused" : ""}`}
                    alt="Rotating Image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="bgPictureOurTeam">
          <div
            className="relative bg-cover bg-left md:bg-center h-[554px] overflow-hidden bg-white bgPictureOurTeam"
            style={{
              transition: "background-image 1s ease", // Smooth transition
            }}
          >
            {/* Navbar */}\
            <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
              <div className="container mx-auto mt-[60px]">
                <div className="contianer-width flex flex-col items-center p-10">
                  <h1 className="missionBoxHeading !text-white !text-6xl">
                    SIFC Secretariat Team{" "}
                  </h1>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 md:py-20 bg-[#073332] flex flex-row justify-center item-center ">
          <div className="contianer-width">
            <div className="whySIFC !text-white !text-center md:!text-[45px]  !text-[25px]">
              Secretariat{" "}
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-white p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={Jamil}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-white pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Mr. Jamil Ahmad Qureshi{" "}
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Secretary<br></br>
                      SIFC
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-white p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={humaira}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-white pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Ms. Humaira Zia Mufti
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Additional Secretary-I<br></br>
                      SIFC
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-white p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={umar}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-white pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Mr. Umar Jahangir{" "}
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Joint Secretary (Projects)<br></br>
                      SIFC
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-white p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={aatif}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-white pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Mr. Aatif Umar Khalil
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Joint Secretary (Country Wing)<br></br>
                      Investment Promotion{" "}
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-white p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={billal}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-white pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Mr. Bilal Khokhar
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Director <br></br>
                      Secretariat & Admin{" "}
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-white p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={adnan}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-white pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Mr. Adnan Munir Rajput{" "}
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Director Projects
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 md:py-20 flex flex-row justify-center item-center ">
          <div className="contianer-width">
            <div className="whySIFC !text-center md:!text-[45px] pb-4 !text-[25px]">
              Sector Coordinators{" "}
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-[#EBEBEB] p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={azam}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-[#EBEBEB] pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Dr. M Azam Niazi{" "}
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Coordinator <br></br>
                      Agriculture & Livestock{" "}
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-[#EBEBEB] p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={amirZaib}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-[#EBEBEB] pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Mr. Aamir Zaib
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Coordinator <br></br>
                      IT & Telecom{" "}
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-[#EBEBEB] p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={qaiser}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-[#EBEBEB] pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Muhammad Qaiser Khan
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Coordinator <br></br>
                      Mines & Minerals{" "}
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-[#EBEBEB] p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={moiz}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-[#EBEBEB] pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Mr. Moiz uddin Ahmed{" "}
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Coordinator <br></br>
                      Energy (Power){" "}
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-[#EBEBEB] p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={tahir}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-[#EBEBEB] pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Mr. Tahir Hussain
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Coordinator <br></br>
                      Energy (Petroleum){" "}
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-[#EBEBEB] p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={asad}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-[#EBEBEB] pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Mr. Asid Hussain Shah
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Coordinator <br></br>
                      Industries{" "}
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              {/* <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-[#EBEBEB] p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={zafar}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-[#EBEBEB] pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Mr. Zafar{" "}
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Coordinator <br></br>
                      Industry, Tourism & Privatisation{" "}
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="py-4 md:py-20 bg-[#073332] flex flex-row justify-center item-center ">
          <div className="contianer-width">
            <div className="whySIFC !text-white !text-center md:!text-[45px] pb-4 !text-[25px]">
              Public Communication Unit{" "}
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-white p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={nighat}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-white pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Ms. Nighat Ahsan{" "}
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Director <br></br>
                      Public Communication Unit{" "}
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-white p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={mahnoor}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-white pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Ms. Mahnoor Ali{" "}
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Public Relations Officer <br></br>
                      Public Communication Unit{" "}
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-2 w-full">
              <div className="flex w-full md:w-4/5 border-[#F08A23] p-4 border-2">
                <img
                  className="bg-white p-2 md:p-4 w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
                  src={marukh}
                  alt=""
                />
                <div className="flex flex-row content-center md:px-8 bg-white pl-3 w-full justify-between items-center">
                  <div>
                    <h5 class="headingTeam !text-base md:!text-[28px]">
                      Ms. Marukh Baig
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Public Relations Officer <br></br>
                      Public Communication Unit{" "}
                    </p>
                    <p class="block md:hidden pTeam md:pt-6 !text-[16px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                  <div className="hidden md:flex justify-center items-center flex-col">
                    <div>
                      <img src={msg} alt="" />
                    </div>
                    <p class="pTeam pt-6 !text-[16px]">invest@sifc.gov.pk</p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="flex flex-row justify-center ">
                <div class="max-w-sm bg-white w-96">
                  <div className="flex justify-center greenb">
                    <div className="border-[#F08A23] w-[150px] h-[150px] md:w-[250px] md:h-[250px] border-2 p-2 md:p-4 !bg-[#073332]">
                      <div
                        class="bg-cover  bg-center w-[130px] h-[130px] md:w-[210px] md:h-[210px] bg-no-repeat "
                        style={{
                          backgroundImage: `url(${naila})`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div class="p-5 mt-10 md:mt-12">
                    <h5 class="headingTeam !text-base md:!text-[24px]">
                      Ms. Naila Nazar{" "}
                    </h5>
                    <p class="pTeam !text-base md:!text-[20px]">
                      Public Relations Officer <br></br>
                      Public Communication Unit{" "}
                    </p>
                    <p class="pTeam !text-base pt-6 md:!text-[20px]">
                      invest@sifc.gov.pk
                    </p>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col justify-center items-center py-[150px]"> */}
      {/* <div className="contianer-width ">
          <div className="headingofAaas text-center">
            Federal & Provincial Representation
          </div>
        </div> */}

      {/* <div className="flex flex-row  pt-[20px] justify-center items-center">
          <div className="contianer-width">
            <div className="grid grid-cols-3 gap-[150px] ">
              <div className=" my-[16px]">
                <div
                  className="flex !flex-row w-[500px] !justify-between accordian-style-leadeship"
                  onClick={() => setIsActiveOne(!isActiveOne)}
                >
                  <div className="flex flex-row  justify-end items-center gap-[10px]">
                    <div className="accordian-style-font">
                      Provincial Leadership
                    </div>
                  </div>
                  <button className="font-bold !text-xl !text-white">
                    {isActiveOne ? "-" : "+"}
                  </button>
                </div>
                {isActiveOne && (
                  <div className="dropdownText border w-[500px] border-[#B9D5C8] rounded text-base  text-left  py-[24px] px-[30px]">
                    <div className="accordian-style-text">
                      <ul>
                        <li className="list-disc">Chief Minister Punjab</li>
                        <li className="list-disc">Chief Minister Sindh </li>
                        <li className="list-disc">
                          {" "}
                          Chief Minister Khyber Pakhtunkhwa
                        </li>
                        <li className="list-disc">
                          {" "}
                          Chief Minister Balochistan
                        </li>
                      </ul>
                    </div>
                    <div className="accordian-style-bold mt-[10px]">
                      {" "}
                      Provincial Cabinet Members
                    </div>
                    <div className="accordian-style-text">
                      <ul>
                        <li className="list-disc">
                          Minister for Planning & Development/ Investment
                        </li>
                        <li className="list-disc">Minister for Finance</li>
                        <li className="list-disc"> Minister for Agriculture</li>
                        <li className="list-disc"> Minister for IT&T</li>
                        <li className="list-disc">
                          Minister for Mines & Minerals
                        </li>
                        <li className="list-disc">Minister for Energy</li>
                        <li className="list-disc"> Minister for Irrigation</li>
                        <li className="list-disc">
                          {" "}
                          Minister for Board of Revenue
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className=" my-[16px]">
                <div
                  className="flex !flex-row w-[500px] !justify-between accordian-style-leadeship"
                  onClick={() => setIsActiveTwo(!isActiveTwo)}
                >
                  <div className="flex flex-row  justify-end items-center gap-[10px]">
                    <div className="accordian-style-font">Federal Cabinet </div>
                  </div>
                  <button className="font-bold !text-xl !text-white">
                    {isActiveTwo ? "-" : "+"}
                  </button>
                </div>
                {isActiveTwo && (
                  <div className="dropdownText border w-[500px] border-[#B9D5C8] rounded text-base  text-left  py-[24px] px-[30px]">
                    <div className="accordian-style-text">
                      <ul>
                        <li className="list-disc">
                          Minister for Planning Development & Special
                          Initiatives
                        </li>
                        <li className="list-disc">Minister for Finance </li>
                        <li className="list-disc">
                          {" "}
                          Minister for National Food Security & Research{" "}
                        </li>
                        <li className="list-disc"> Minister for IT &Telecom</li>

                        <li className="list-disc">Minister for Power</li>
                        <li className="list-disc">
                          Minister of State (Petroleum)
                        </li>
                        <li className="list-disc">
                          {" "}
                          Minister of State (Finance){" "}
                        </li>
                        <li className="list-disc">
                          {" "}
                          Minister for Water Resources
                        </li>
                        <li className="list-disc">
                          Minister for Industries and Production
                        </li>
                        <li className="list-disc"> Minister for Defence</li>
                        <li className="list-disc">
                          {" "}
                          Minister for Defence Production
                        </li>

                        <li className="list-disc">
                          Minister for Defence Production
                        </li>
                        <li className="list-disc">
                          {" "}
                          Minister for Information and Broadcasting
                        </li>
                        <li className="list-disc"> Minister for Interior </li>
                      </ul>
                    </div>
          
                  </div>
                )}
              </div>
              <div className=" my-[16px]">
                <div
                  className="flex w-[500px] !flex-row !justify-between accordian-style-leadeship"
                  onClick={() => setIsActiveThree(!isActiveThree)}
                >
                  <div className="flex flex-row  justify-end items-center gap-[10px]">
                    <div className="accordian-style-font">
                      Army Representation{" "}
                    </div>
                  </div>
                  <button className="font-bold !text-xl !text-white">
                    {isActiveThree ? "-" : "+"}
                  </button>
                </div>

 
                {isActiveThree && (
                  <div className="dropdownText border w-[500px] border-[#B9D5C8] rounded text-base  text-left  py-[24px] px-[30px]">
                    <div className="accordian-style-text">
                      <ul>
                        <li className="list-disc">
                          Chief of the Army Staff - On Special invitation
                        </li>
                        <li className="list-disc">
                          Two Stars General - Director General SIFC{" "}
                        </li>
                        <li className="list-disc">
                          {" "}
                          Senior Army Officers - Sectoral Working Groups
                        </li>
                        <li className="list-disc">
                          {" "}
                          Junior Army Officers - Sectoral Co-Coordinators{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div> */}
      {/* </div> */}

      <Footer />
    </div>
  );
};

export default Leadership_org;
