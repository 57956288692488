import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ArrowLeft from "../../assests/Icons/Frame 34.png";
import ArrowRight from "../../assests/Icons/Frame 33.png";
const apiUrl = process.env.REACT_APP_API_URL;
const myURLForImages = process.env.REACT_APP_STAGGING_URL_FOR_Images;
function NewsandUpdates() {
  const [news, setNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const videos = [
    "https://www.youtube.com/embed/CuwH_FJp1V4",
    "https://www.youtube.com/embed/RBxPPmJKIYQ",
    "https://www.youtube.com/embed/kQjnBShRSKY",
    "https://www.youtube.com/embed/W79Xtrvh-oI",
    "https://www.youtube.com/embed/wrHGvqUZZj8",
    "https://www.youtube.com/embed/EIkivq8S1Lw",
    "https://www.youtube.com/embed/O_XDxVcoUM0",
    "https://www.youtube.com/embed/qKqsmEvOB48",
    "https://www.youtube.com/embed/E1EGeF2YD40",
    "https://www.youtube.com/embed/E1EGeF2YD40",
    "https://www.youtube.com/embed/Oc_-Rd9mgXs",
    "https://www.youtube.com/embed/m1QAuVk85pQ",
    "https://www.youtube.com/embed/WOf4AnEzW2E",
    "https://www.youtube.com/embed/xK3A2CPAGHQ",
    "https://www.youtube.com/embed/a_Kik2ZpNsM",
    "https://www.youtube.com/embed/nNOogws6WmM",
    "https://www.youtube.com/embed/zMUnQ3IvbR8",
    "https://www.youtube.com/embed/L7WMPrOz7UM",
    "https://www.youtube.com/embed/D-ABQuy0kq4",
    "https://www.youtube.com/embed/mJFnfSOR0ZA",
  ];
  const handlePrevLeadership = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? videos.length - 1 : prevIndex - 1
    );
  };

  const handleNextLeadership = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === videos.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}all-news`);
        setNews(response?.data?.data);
        console.log("news", response?.data?.data);
      } catch (error) {
        // setError(error);
        // console.log(error);
      } finally {
        // console.log("finally");
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const originalDate = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return originalDate.toLocaleDateString("en-US", options);
  };

  // const firstFiveElements = news?.slice(0, 6);

  return (
    <>
      <div className="bg-newsandUpdates py-4 md:py-[100px] xl:px-[100px] xxl:px-[100px] 2xl:px-0  xl:py-[100px] flex flex-row  flex-wrap justify-center items-center ">
        <div className="container-width-SIFC-faclitates mx-4">
          <div className="flex flex-row justify-center items-center">
            <div className="block headingTestonimial text-[35px] md:text-[48px]  text-center">
              News & Updates
            </div>
          </div>
          <div className="flex flex-row justify-end gap-[25px] xxl:mt-[30px]">
            {/* <button onClick={handlePrevLeadership}>
              <img src={ArrowLeft} alt="Previous" />
            </button>
            <button onClick={handleNextLeadership}>
              <img src={ArrowRight} alt="Next" />
            </button> */}
          </div>
          <div className="grid lg:grid-cols-12 grid-cols-8   gap-4 mt-[10px]">
            <div className="col-span-8  z-10 bg-gradient-to-b from-teal-950 to-teal-950 rounded">
              <div>
                {/* <div className="">
                  <iframe
                    width="100%"
                    className="rounded w-full h-[300px] md:h-[480px] xl:h-[483px] xxl:h-[583px]"
                    src={videos[currentIndex] + "?enablejsapi=1"}
                    title={`Video ${currentIndex + 1}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div> */}
                {/* <div className="relative">
                  <div className="video-container rounded overflow-hidden mb-4">
                    <iframe
                      className="w-full h-[300px] md:h-[480px] xl:h-[483px] xxl:h-[583px]"
                      src={videos[currentIndex] + "?enablejsapi=1"}
                      title={`Video ${currentIndex + 1}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="flex justify-between">
                    <button
                      className={`px-4 py-2 bg-gray-300 rounded ${
                        currentIndex === 0
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                      disabled={currentIndex === 0}
                    >
                      Previous
                    </button>
                    <button
                      className={`px-4 py-2 bg-gray-300 rounded ${
                        currentIndex === videos.length - 1
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                      disabled={currentIndex === videos.length - 1}
                    >
                      Next
                    </button>
                  </div>
                </div> */}
                <div className="relative">
                  <iframe
                    className="w-full h-[300px] md:h-[480px] xl:h-[483px] xxl:h-[583px] rounded"
                    src={videos[currentIndex] + "?enablejsapi=1"}
                    title={`Video ${currentIndex + 1}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                  <button
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 hover:bg-[#073332] rounded-full "
                    onClick={handlePrevLeadership}
                    // className="bg-grey p-1 rounded-full"
                  >
                    {" "}
                    <img src={ArrowLeft} alt="Previous" />
                  </button>
                  <button
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 hover:bg-[#073332] rounded-full "
                    onClick={handleNextLeadership}
                    // className="bg-[#073332] p-1 rounded-full"                    &rarr;
                  >
                    <img src={ArrowRight} alt="Next" />
                  </button>
                </div>
              </div>
            </div>

            <div className=" col-span-8 lg:col-span-4 mt-4 sm:mt-0">
              <div className=" border-l  p-4  h-[300px] md:h-[480px] xl:h-[483px] xxl:h-[583px] overflow-y-scroll">
                <ul>
                  {news &&
                    news?.map((news) => (
                      <>
                        <li className=" xl:mb-[10px] my-[10px] md:my-[20px] cursor-pointer">
                          <Link to={`/news/${news?.id}`} key={news?.id}>
                            <div className="NewsTitle123 !text-[16px] xl:!text-[18px] xxl:!text-[20px]">
                              {news?.title}
                            </div>{" "}
                            <div className="newsDate mt-[5px]">
                              {formatDate(news?.date)}
                              {/* {news.date} */}
                            </div>
                          </Link>
                        </li>
                        <hr className="" />{" "}
                      </>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NewsandUpdates;
